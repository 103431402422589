<template>
  <div class="c-modal-mask" @click="hide" v-show="visible" transition="modal">
    <div class="c-modal-container" @click.stop>
      <div>
        <h3>{{ title }}</h3>
        <p v-html="message"></p>
        <button v-on:click.prevent="hide()" class="c-button c-button--small c-button--secondary">
          {{ $t('cancel') }}
        </button>
        <button v-on:click.prevent="confirm()" class="c-button c-button--small c-button--primary">
          {{ $t('confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import modals from './modals.js';

export default {
  name: 'modal',
  data: function () {
    return {
      visible: false,
      title: 'title',
      message: 'message',
      onConfirm: undefined,
      onCancel: undefined,
    };
  },
  updated: function () {
    if (this.visible) {
      document.querySelector('html').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'scroll';
    }
  },
  methods: {
    hide() {
      this.visible = false;
    },
    cancel() {
      if (typeof this.onCancel === 'function') {
        this.onCancel();
      }
      this.hide();
    },
    confirm() {
      if (typeof this.onConfirm === 'function') {
        // run passed function and then close the modal
        this.onConfirm();
        this.hide();
      } else {
        // we only close the modal
        this.hide();
      }
    },
    show: function (params) {
      this.title = params.title;
      this.message = params.message;
      this.onCancel = params.onCancel;
      this.onConfirm = params.onConfirm;
      this.visible = true;
    },
  },
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    modals.EventBus.$on('show', (params) => {
      this.show(params);
    });
  },
};
</script>
