<template>
  <div class="c-input c-input--text">
    <label class="c-input__label" :for="inputName">{{getLabel()}}</label>
    <password ref="input" v-model="value2" :id="inputName" classes="c-input__field"
              :disableToggle="! settings.showToggle" :disableStrength="! settings.showStrength"
              @input="updateStrength" :strength="strengthScore" />
  </div>
</template>

<script>
  import base from './sceInputBase.vue';

  import clone from 'lodash/clone';
  import merge from 'lodash/merge';

  import { zxcvbn } from '@zxcvbn-ts/core'

  export default {
    name: "sce-password-input",
    extends: base,
    defaultConfig: {
      showStrength: false,
      showToggle: true,
    },
    strengthOptions: {
    },
    components: {
      password: require('vue-password').default,
    },
    data: function() {
      return {
        value2: null,
        strengthScore: 0,
      };
    },
    computed: {
      settings: function () {
        return merge(clone(this.$options.defaultConfig), this.config);
      },
    },
    created: function() {
      this.value2 = this.value;
    },
    watch: {
      value2: function() {
        this.updateInput(this.value2);
      },
    },
    methods: {
      updateStrength: function(password) {
        if (password === undefined || ! this.settings.showStrength) {
          this.strengthScore = 0;
          return;
        }
        const res = zxcvbn(password);
        console.log(res);
        this.strengthScore = res.score;
      }
    },
  }
</script>
