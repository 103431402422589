import Vue from 'vue';
import socket from 'client/socket';

export default function(store) {
  const state = {
    block: undefined
  };

  const actions = {
    "block.set": function(ctx, item) {
      if (ctx.state.block) {
        socket.emit('leave', 'block:' + ctx.state.block.id);
      }
      store.commit("block.set", item);
      if (item) {
        socket.emit('join', 'block:' + item.id);
      }
    }
  };

  const mutations = {
    "block.set": function(state, item) {
      Vue.set(state, 'block', item);
    }
  };

  function listener() {
    socket.on('block.update', function(result) {
      if (! state.block) {
        // abort if module is not initialised
        return;
      }

      if (result.data.id === state.block.id) {
        store.commit('block.set', result.data);
      }
    });
  }

  listener();

  store.registerModule('block', {
    state: state,
    mutations: mutations,
    actions: actions
  })
}
