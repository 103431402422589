import Vue from 'vue';
import socket from "client/socket";
import config from "client/config";

const filter = require("lodash/filter");

export default function (store) {
  let state = {
    items: [],
    userId: null,
  };

  const actions = {
    "displayTemplates.load": function(ctx, opts) {
      if (! opts.userId) {
        console.log('no user id');
        return null;
      }

      leaveRooms()
      store.commit('displayTemplates.setUserId', opts.userId)

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/displaySets/getSets/', {params: opts}).then(result => {
          store.commit("displayTemplates.setItems", result.data)
          socket.emit('join', 'displaySet:' + ctx.state.userId)
          resolve()
        }, err => reject(err))
      });
    },
    "displayTemplate.update": function(ctx, opts) {
      return new Promise((resolve, reject) => Vue.http.patch(config.root + '/displaySets/' + opts.id,
        opts.set).then(result => {
        store.commit("displayTemplate.update", result.data);
        resolve(result.data);
      }, err => reject(err)));
    },
    "displayTemplate.add": function(ctx, opts) {
      let data = opts.data;
      data.owner_id = ctx.state.userId;
      return new Promise((resolve, reject) =>
        Vue.http.post(config.root + '/seUsers/' + data.owner_id + '/displaySets', data).then((result) => {
        resolve(result.data);
      }, err => reject(err)));
    },
    "displayTemplate.remove": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/displaySets/' + opts.id).then(() => {
          store.commit('displayTemplate.remove', opts.id);
          resolve();
        }, err => reject(err));
      });
    },
  };

  function leaveRooms() {
    if (state.eventId) {
      socket.emit('leave', 'displaySet:' + state.userId);
      store.commit("displayTemplates.setUserId", undefined);
    }
  }

  const mutations = {
    "displayTemplates.setItems": function(state, items) {
      Vue.set(state, 'items', items)
    },
    "displayTemplates.setUserId": function(state, userId) {
      Vue.set(state, 'userId', userId);
    },
    "displayTemplate.remove": function(state, id) {
      state.items = filter(state.items, function(item) {
        return item.id !== id;
      });
    },
    "displayTemplate.add": function(state, displayTemplate) {
      state.items.push(displayTemplate);
    },
    "displayTemplate.update": function(state, displayTemplate) {
      const index = state.items.findIndex(i => i.id === displayTemplate.id);
      let templates = state.items;
      if (index >= 0) {
        templates.splice(index, 1, displayTemplate);
      }
      else {
        templates.push(displayTemplate);
      }

      Vue.set(state, 'items', templates);
    }
  };

  function listener() {
    /*socket.on('connect', function () {
      if (state.userId) {
        store.dispatch('displayTemplates.load', {userId: state.userId});
      }
    });*/
    socket.on('displaySet.update', function(result) {
      console.log('displayTemplate update', result);
      if (result.type === 'update') {
        store.commit('displayTemplate.update', result.data);
        return;
      }
      if (result.type === 'create') {
        store.commit('displayTemplate.add', result.data);
        return;
      }
    });
    socket.on('displayTemplate.delete', result => {
      store.commit('displayTemplate.remove', result.data);
    });
    socket.emit('join', 'displayTemplate.delete');
  }

  store.registerModule('displayTemplates', {
    state: state,
    mutations: mutations,
    actions: actions,
  });

  listener();
}
