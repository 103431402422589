<template>
  <div class="c-input">
    <label class="c-input__label" :for="inputName">{{getLabel()}}</label>
    <input ref="input" :value="value" @input="updateInput()" class="c-input__field"
           :name="inputName" :id="inputName" type="number" :min="min" :max="max" :step="step"
           :disabled="disabled" />
  </div>
</template>

<script>
  import base from './sceInputBase.vue';

  export default {
    extends: base,
    name: "sce-number-input",
    props: ['min', 'max', 'step'],
  }
</script>

<style scoped>
</style>
