'use strict';

const filter = require('lodash.filter');
const forEach = require('lodash.foreach');
const repeat = require('lodash.repeat');

const math = require('mathjs');

module.exports = {

  valueTypeFormat: function(valueType) {
    let format = '0';
    if (valueType.precision > 0) {
      const precision = math.ceil(valueType.precision);
      format += '.' + repeat('0', precision);
    }
    return format;
  },

  equalProperties: function(p1, p2) {
    if (! (p1.position === p2.position && p1.index === p2.index && p1.leg === p2.leg)) {
      return false;
    }

    return (
      p1.group === p2.group && p1.element === p2.element
    );
  },

  exerciseGetValue: function(exercise, properties) {
    const _this = this;
    const values = filter(exercise.scoreValues, function(value) {
      return _this.equalProperties(value, properties);
    });
    if (values.length) {
      return values[0];
    }
    return undefined;
  },

  extractCsvScores: function(exercise) {
    let scores = {};

    forEach(exercise.scoreValues, value => {
      let pass = scores[value.leg];
      if (! pass) {
        pass = {};
      }
      let group = pass[value.group.replace('.', '_')];
      if (! group) {
        group = {};
      }

      let element = group[value.element.replace('.', '_')];
      if (! element) {
        element = {};
      }

      let position = element[value.position];
      if (! position) {
        position = {};
      }

      position[value.index] = value.publicValue;
      element[value.position] = position;
      group[value.element.replace('.', '_')] = element;
      pass[value.group.replace('.', '_')] = group;
      scores[value.leg] = pass;
    });
    return scores;
  },
};
