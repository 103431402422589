
import AWN from 'awesome-notifications';
import 'vue-awesome-notifications/dist/styles/style.css';

const awnOptions = {
  maxNotifications: 6,
  position: 'top-right',
  durations: {
    global: 3500,
  },
  icons: {
    enabled: true,
  }
};
let notifier = new AWN(awnOptions);

const Notify = {
  install(Vue) {
    Vue.prototype.$notify = {
      saveAsync(type, promise, onSuccess = null) {
        const onResolve = onSuccess ? (resp) => {
            onSuccess(resp);
            notifier.success(Vue.i18n.t('notify.saveAsync.success', {type: type}));
          } :
          Vue.i18n.t('notify.saveAsync.success', {type: type});

        this.msgAsync(promise,
          Vue.i18n.t('notify.saveAsync.processing', {type: type}),
          onResolve,
          Vue.i18n.t('notify.saveAsync.error', {type: type}),
          {labels: {async: Vue.i18n.t('notify.saveAsync.label')}});
      },

      removeAsync(type, promise, onSuccess = null) {
        const onResolve = onSuccess ? (resp) => {
            onSuccess(resp);
            notifier.success(Vue.i18n.t('notify.removeAsync.success', {type: type}));
          } :
          Vue.i18n.t('notify.removeAsync.success', {type: type});

        this.msgAsync(promise,
          Vue.i18n.t('notify.removeAsync.processing', {type: type}),
          onResolve,
          Vue.i18n.t('notify.removeAsync.error', {type: type}),
          {labels: {async: Vue.i18n.t('notify.removeAsync.label')}});
      },

      notifyAsync(promise, processingMsg, onSuccess, onFail) {
        const onResolve = typeof onSuccess === 'function' ? (resp) => {
            const message = onSuccess(resp);
            console.log(message)
            notifier.success(message);
          } : onSuccess

        const onReject = typeof onFail === 'function' ? (err) => {
            const message = onFail(err)
            console.log(message)
            notifier.alert(message);
          } : onFail

        this.msgAsync(promise,
          processingMsg,
          onResolve,
          onReject);
      },

      msgAsync(promise, processingMsg, onResolve, onReject, options = {}) {
        notifier.async(promise, onResolve, onReject, processingMsg, options);
      },

      info(message) {
        notifier.info(message);
      },

      warning(message) {
        notifier.warning(message);
      }
    }
  }
};

export default Notify;
