import Vue from 'vue';
import config from 'client/config';
import socket from "client/socket";

const filter = require("lodash/filter");

export default function(store) {
  const state = {
    eventDisciplineId: undefined,
    rounds: [],
    discipline: undefined,
    panelTypes: [],
    formats: [],
    tieBreaks: [],
    exerciseTypes: [],
  };

  const actions = {
    "eventDiscipline.get": function(ctx, opts) {
      if (ctx.state.eventDisciplineId) {
        socket.emit('leave', 'round:' + ctx.state.eventDisciplineId);
      }
      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/eventDisciplines/' + opts.eventDisciplineId + '/get-info').then(result => {
          store.commit('eventDiscipline.setId', opts.eventDisciplineId);
          store.commit('eventDiscipline.setData', result.data);
          socket.emit('join', 'round:' + ctx.state.eventDisciplineId);
          resolve(result);
        }, err => reject(err));
      });
    },
    "round.save": function(ctx, round) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/rounds/' + round.id, round).then(result => {
          store.commit('rounds.update', result.data);
          resolve(result);
        }, error => { reject(error)});
      });
    },
    "round.add": function(ctx, opts) {
      return Vue.http.post(config.root + '/eventDisciplines/' + opts.eventDisciplineId + '/rounds', {
        "name": Vue.i18n.t('Round') + ' ' + (opts.index+1),
        "index": opts.index,
        "eventDisciplineId": opts.eventDisciplineId,
      });
    },
    "round.remove": function(ctx, round) {
      store.commit('rounds.remove', round.id);
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/rounds/' + round.id).then(result => {
          store.commit('rounds.remove', round.id);
          resolve(result);
        }, error => {reject(error)});
      });
    }
  };

  const mutations = {
    "eventDiscipline.setId": function(state, id) {
      Vue.set(state, 'eventDisciplineId', id);
    },
    "eventDiscipline.setData": function(state, data) {
      Vue.set(state, 'rounds', data.rounds);
      Vue.set(state, 'discipline', data.discipline);
      Vue.set(state, 'panelTypes', data.panelTypes);
      Vue.set(state, 'formats', data.formats);
      Vue.set(state, 'tieBreaks', data.tieBreaks);
      Vue.set(state, 'exerciseTypes', data.exerciseTypes);
    },
    "rounds.remove": function(state, id) {
      state.rounds = filter(state.rounds, function(item) {
        return item.id !== id;
      })
    },
    "rounds.update": function(state, round) {
      const index = state.rounds.findIndex(i => i.id === round.id);
      let rounds = state.rounds;
      if (index >= 0) {
        rounds.splice(index, 1, round);
      } else {
        rounds.push(round);
      }
      Vue.set(state, 'rounds', rounds);
    },
  };

  function listener() {
    /*socket.on('connect', function () {
      if (state.eventDisciplineId) {
        store.dispatch('eventDiscipline.get', {eventDisciplineId: state.eventDisciplineId});
      }
    });*/
    socket.on('round.update', function(result) {
      store.commit('rounds.update', result.data);
    });
    socket.on('round.delete', result => {
      store.commit('rounds.remove', result.data);
    });
    socket.emit('join', 'round.delete');
  }

  store.registerModule('eventDiscipline', {
    state: state,
    mutations: mutations,
    actions: actions,
  });

  listener();
}
