<template>
  <button class="c-button" :class="{'c-button--icon': icon, 'c-button--disabled': disabled}"
          v-on:click.prevent="$emit('click')">
    <slot>
      <svg v-if="icon" role="img" class="c-button__icon" fill="white">
        <use :href="'images/' + icon"></use>
      </svg>
      <span v-if="label" class="c-button__label">{{$t(label)}}</span>
    </slot>
  </button>
</template>

<script>
  export default {
    name: "sceButton",
    props: ['label', 'icon', 'disabled'],
  }
</script>
