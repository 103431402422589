<template>
  <div class="c-input">
    <label class="c-input__label" :for="inputName">
      {{ $t('customField.' + config.label) }}
    </label>
    <input ref="input" :value="value" @input="updateInput($event)" class="c-input__field"
           :name="inputName" :id="inputName" type="number" :min="config.min" :max="config.max" :step="config.step"
    />
  </div>
</template>

<script>
import base from './customFieldBase.vue';

export default {
  name: "customFieldNumber",
  extends: base,
  created: function() {
    this.numeric = true
  },
}
</script>

<style scoped>

</style>
