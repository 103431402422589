<template>
  <div class="c-input">
    <label v-if="label" class="c-input__label" :for="inputName">{{getLabel()}}</label>
    <select class="c-input__field c-input__field-select" :value="value" @input="updateInput()"
            :id="inputName" :name="inputName" ref="input" v-on:change="$emit('change', $event)"
            :disabled="disabled" >
      <template v-if="config.emptyLabel">
        <option :value="null">
          {{$t(config.emptyLabel)}}
        </option>
      </template>
      <template v-if="settings.groups">
        <optgroup v-for="group in options" :label="getGroupLabel(group)" :key="group.label">
          <template v-for="option in group[settings.groupOptions]">
            <slot name="option" v-bind:option="option">
              <option :value="option[settings.optionId]" :key="option[settings.optionId]">
                {{getOptionLabel(option)}}
              </option>
            </slot>
          </template>
        </optgroup>
      </template>
      <template v-else>
        <template v-for="option in options">
          <slot name="option" v-bind:option="option">
            <option :value="option[settings.optionId]" :key="option[settings.optionId]">
              {{getOptionLabel(option)}}
            </option>
          </slot>
        </template>
      </template>
    </select>
  </div>
</template>

<script>
  import base from './sceInputBase.vue';

  import clone from 'lodash/clone';
  import merge from 'lodash/merge';

  export default {
    name: "sceSelect",
    extends: base,
    defaultConfig: {
      optionId: 'id',
      optionLabel: 'name',
      groups: false,
      groupLabel: 'name',
      groupOptions: 'elements',
      localized: true
    },
    props: {
      options: {
        type: Array,
        required: true
      },
    },
    computed: {
      settings: function() {
        return merge(clone(this.$options.defaultConfig), this.config);
      }
    },
    methods: {
      getOptionLabel: function(option) {
        let label = option[this.settings.optionLabel];
        if (this.settings.localized) {
          label = this.$t(label);
        }
        return label;
      },
      getGroupLabel: function(group) {
        let label = group[this.settings.groupLabel];
        if (this.settings.localized) {
          label = this.$t(label);
        }
        return label;
      },
    },
  }
</script>

<style scoped>

</style>
