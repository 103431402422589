<template>
  <div class="c-input c-input--radio">
    <input :value="option" :checked="value === option" @change="emitInput()" ref="input"
           :id="inputName" :name="inputName" type="radio" class="c-input__field c-input__field-radiobutton"
           :disabled="disabled" />
    <label :for="inputName" class="c-input__label c-input__label-radiobutton">
      {{getLabel()}}
    </label>
  </div>
</template>

<script>
  import base from './sceInputBase.vue';

  export default {
    name: "sce-radio-button",
    extends: base,
    props: ['option'],
    methods: {
      emitInput: function() {
        console.log('emit', this.option);
        this.$emit('input', this.option);
      }
    }
  }
</script>
