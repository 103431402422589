<template>
  <div class="c-input c-input--text">
    <label class="c-input__label" :for="inputName">{{getLabel()}}</label>
    <input ref="input" :value="value" @input="updateInput()" class="c-input__field"
           :name="inputName" type="email" :id="inputName" :disabled="disabled" />
  </div>
</template>

<script>
  import base from './sceInputBase.vue';

  export default {
    name: "sce-text-input",
    extends: base,
  }
</script>
