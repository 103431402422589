<template>
  <sceTextField ref="input" :value="value" :name="name" :label="'customField.' + config.label" @input="updateInput($event)"/>
</template>

<script>
import base from './customFieldBase.vue';

export default {
  name: "customFieldString",
  extends: base,
}
</script>

<style scoped>

</style>
