<template>
  <div class="c-input">
    <label v-if="label" class="c-input__label" :for="inputName">{{getLabel()}}</label>
    <multi-select :value="value" :options="options" ref="input" class="c-input--multiselect"
                  :multiple="true" :searchable="false" :label="settings.optionLabel" :track-by="settings.optionId"
                  :taggable="true" :hideSelected="true" :placeholder="$t(placeholder)" :closeOnSelect="false"
                  :selectLabel="$t('select.selectOnEnter')"
                  @select="selectValue($event)"
                  @remove="removeValue($event)"
    />
  </div>
</template>

<script>
  import base from './sceInputBase.vue';

  import clone from 'lodash/clone';
  import filter from 'lodash/filter';
  import merge from 'lodash/merge';

  import vueMultiSelect from 'vue-multiselect';
  import 'vue-multiselect/dist/vue-multiselect.min.css';

  export default {
    name: "sceMultiSelect",
    extends: base,
    components: {
      'multi-select': vueMultiSelect,
    },
    defaultConfig: {
      optionId: 'id',
      optionLabel: 'name',
    },
    props: ['options', 'placeholder'],
    computed: {
      settings: function() {
        return merge(clone(this.$options.defaultConfig), this.config);
      }
    },
    methods: {
      selectValue: function(event) {
        const value = clone(this.value);
        value.push(event);
        this.updateInput(value);
      },
      removeValue: function(event) {
        const value = filter(this.value, v => v.id !== event.id);
        this.updateInput(value);
      },
      updateInput: function(value) {
        this.$emit('input', value);
      }
    },
  }
</script>
