import Vue from 'vue';
import socket from "client/socket";
import config from "client/config";

const filter = require("lodash/filter");

export default function (store) {
  let state = {
    items: [],
    eventId: null,
  };

  const actions = {
    "displayImages.load": function(ctx, opts) {
      console.log('load images');
      if (! opts.eventId) {
        console.log('no event id');
        return null;
      }

      leaveRooms();
      store.commit('displayImages.setEventId', opts.eventId);

      return new Promise(
        (resolve, reject) => Vue.http.get(config.root + '/events/' + ctx.state.eventId + '/displayImages/')
          .then(result => {
        store.commit("displayImages.setItems", result.data);
        socket.emit('join', 'displayImage:' + state.eventId);
        resolve();
      }, err => reject(err)));
    },
    "displayImage.update": function(ctx, opts) {
      return new Promise((resolve, reject) => Vue.http.patch(config.root + '/displayImages/' + opts.id,
        opts.displayImage).then(result => {
        store.commit("displayImage.update", result.data);
        resolve(result.data);
      }, err => reject(err)));
    },
    "displayImage.add": function(ctx, opts) {
      let data = opts.data;
      data.eventId = ctx.state.eventId;
      return new Promise(
        (resolve, reject) => Vue.http.post(config.root + '/events/' + ctx.state.eventId + '/displayImages',
          data).then((result) => {
        // store.commit('displayImage.add', result.data);
        resolve(result.data);
      }, err => reject(err)));
    },
    "displayImage.remove": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/displayImages/' + opts.id).then(() => {
          store.commit('displayImage.remove', opts.id);
          resolve();
        }, err => reject(err));
      });
    },
  };

  function leaveRooms() {
    if (state.eventId) {
      socket.emit('leave', 'displayImage:' + state.eventId);
      store.commit("displays.setEventId", undefined);
    }
  }

  const mutations = {
    "displayImages.setItems": function(state, items) {
      Vue.set(state, 'items', items)
    },
    "displayImages.setEventId": function(state, eventId) {
      Vue.set(state, 'eventId', eventId);
    },
    "displayImage.remove": function(state, id) {
      state.items = filter(state.items, function(item) {
        return item.id !== id;
      });
    },
    "displayImage.add": function(state, display) {
      state.items.push(display);
    },
    "displayImage.update": function(state, displayImage) {
      const index = state.items.findIndex(i => i.id === displayImage.id);
      let images = state.items;
      if (index >= 0) {
        images.splice(index, 1, displayImage);
      }
      else {
        images.push(displayImage);
      }

      Vue.set(state, 'items', images);
    }
  };

  function listener() {
    /*socket.on('connect', function () {
      if (state.eventId) {
        store.dispatch('displayImages.load', {eventId: state.eventId});
      }
    });*/
    socket.on('displayImage.update', function(result) {
      if (result.type === 'update') {
        store.commit('displayImage.update', result.data);
        return;
      }
      if (result.type === 'create') {
        store.commit('displayImage.add', result.data);
        return;
      }
    });
    socket.on('displayImage.delete', result => {
      store.commit('displayImage.remove', result.data);
    });
    socket.emit('join', 'displayImage.delete');
  }

  store.registerModule('displayImages', {
    state: state,
    mutations: mutations,
    actions: actions,
  });

  listener();
}
