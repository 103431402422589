import config from 'client/config';
import find from "lodash/find";

import Vue from'vue';

export default function (store) {
  const state = {
    instance: undefined,
    roles: [],
    planningDefaults: [],
  };

  const actions = {
    "user.set": function (ctx, creds) {
      return Vue.http.get(config.root +'/seUsers/' + creds.id + '/profile').then((result) => {
        result.data.token = creds.token;
        store.commit('user.set', result.data.user);
        store.commit('user.setRoles', result.data.roles);
        store.commit('user.setPlanningDefaults', result.data.planningDefaults);
        if (result.data.locale) {
          Vue.i18n.locale = result.data.locale;
        }
      });
    },
    "user.update": function (ctx, data) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/seUsers/' + data.id, data).then(
          result => {
            if (result.data.id === ctx.state.instance.id) {
              store.commit('user.set', result.data);
            }
            resolve(result);
          },
          error => {
            reject(error);
          });
      });
    },
    "user.logout": function () {
      store.commit('user.set');
    },
  };

  const getters = {
    user: (state) =>  (userId) => {
      if (userId === state.instance.id) {
        return state.instance;
      }

      return find(store.state.users.items, item => item.id === userId);
    },
  };

  const mutations = {
    "user.set": function (state, inst) {
      Vue.set(state, 'instance', inst);
    },
    "user.setRoles": function (state, roles) {
      Vue.set(state, 'roles', roles);
    },
    "user.setPlanningDefaults": function (state, defaults) {
      Vue.set(state, 'planningDefaults', defaults);
    },
  };

  store.registerModule('user', {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
  });
}
