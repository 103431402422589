'use strict';

import toUpper from "lodash/toUpper";
import trim from "lodash/trim";

import store from '@/store'

export default {
  getName: function(member) {
    if (! member) {
      return 'missing';
    }
    return toUpper(trim(member.lastName)) + ' ' +
      trim(member.firstname).replace(/[^-'\s]+/g, function(word) {
        return word.replace(/^./, function(first) {
          return first.toUpperCase();
        });
      });
  },

  getClubRegion: function(club) {
    if (! club.countryId || ! club.regionId) return null

    const country = store.state.events.countries.find(c => c.id === club.countryId)
    return country.regions.find(r => r.id === club.regionId)
  }
};
