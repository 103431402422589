import Vue from 'vue';
import socket from 'client/socket';
import config from 'client/config';

const filter = require('lodash/filter');

export default function (store) {
  const state = {
    items: [],
    importData: undefined,
    eventDisciplineId: null,
  };

  const actions = {
    'imports.get': function (ctx, opts) {
      return new Promise((resolve, reject) => {
        if (ctx.state.eventDisciplineId) {
          socket.emit('leave', 'import:' + ctx.state.eventDisciplineId);
        }
        store.commit('imports.setEventDisciplineId', opts.eventDisciplineId);
        Vue.http.get(config.root + '/eventDisciplines/' + opts.eventDisciplineId + '/imports').then(
          (result) => {
            store.commit('imports.setItems', result.data);
            socket.emit('join', 'import:' + opts.eventDisciplineId);
            resolve(result);
          },
          (err) => reject(err)
        );
      });
    },
    'import.load': function (ctx, importId) {
      if (ctx.state.importData) {
        socket.emit('leave', 'importData:' + ctx.state.importData.importId);
      }
      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/imports/' + importId + '/importData').then(
          (result) => {
            store.commit('import.setData', result.data[0]);
            socket.emit('join', 'importData:' + importId);
            resolve(result);
          },
          (err) => reject(err)
        );
      });
    },
    'import.add': function (ctx, data) {
      return new Promise((resolve, reject) => {
        data.eventDisciplineId = ctx.state.eventDisciplineId;
        Vue.http.post(config.root + '/eventDisciplines/' + data.eventDisciplineId + '/addImport', { data: data }).then(
          (result) => {
            store.commit('import.update', result.data.import);
            resolve(result.data.import);
          },
          (err) => reject(err)
        );
      });
    },
    'import.execute': function (ctx, importId) {
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/imports/' + importId + '/execute').then(
          (result) => {
            store.commit('import.update', result.data.import);
            resolve(result.data.import);
          },
          (err) => reject(err)
        );
      });
    },
    'import.delete': function (ctx, opts) {
      return Vue.http.delete(config.root + '/imports/' + opts.importId);
    },
  };

  const mutations = {
    'imports.setEventDisciplineId': function (state, eventDisciplineId) {
      Vue.set(state, 'eventDisciplineId', eventDisciplineId);
    },
    'imports.setItems': function (state, items) {
      Vue.set(state, 'items', items);
    },
    'import.setData': function (state, item) {
      Vue.set(state, 'importData', item);
    },
    'import.remove': function (state, id) {
      state.items = filter(state.items, (item) => {
        return item.id !== id;
      });
    },
    'import.update': function (state, importItem) {
      const index = state.items.findIndex((i) => i.id === importItem.id);
      const imports = state.items;
      if (index >= 0) {
        imports.splice(index, 1, importItem);
      } else {
        imports.push(importItem);
      }

      Vue.set(state, 'items', imports);
    },
  };

  function listener() {
    /*socket.on('connect', function () {
      if (state.eventDisciplineId) {
        store.dispatch('imports.get', { eventDisciplineId: state.eventDisciplineId });
      }
    });*/

    socket.on('import.update', function (result) {
      if (result.type === 'update') {
        store.commit('import.update', result.data);
        return;
      }
      if (result.type === 'create') {
        store.commit('import.update', result.data);
      }
    });

    socket.on('importData.update', function (result) {
      if (result.type === 'update') {
        store.commit('import.setData', result.data);
      }
    });

    socket.on('import.delete', function (result) {
      store.commit('import.remove', result.data);
    });
    socket.emit('join', 'import.delete');
  }

  store.registerModule('imports', {
    state: state,
    mutations: mutations,
    actions: actions,
  });

  listener();
}
