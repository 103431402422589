'use strict';

import store from 'client/store';

import rankingLib from 'client/lib/ranking'

import find from "lodash/find";
import forEach from "lodash/forEach";
import map from "lodash/map";
import merge from "lodash/merge";

export default {

  getFormat: function(categoryId, roundId) {
    const category = find(store.state.categories.items, item => item.id === categoryId)
    const categoryRound = find(category.rounds, item => item.roundId === roundId)
    return find(store.state.eventDiscipline.formats, item => item.id === categoryRound.formatId)
  },

  /** get combined exercise configuration based on inherited configurations */
  getConfiguration: function(categoryId, roundId, exerciseTypeId = null) {
    const discipline = store.state.eventDiscipline.discipline;
    const format = this.getFormat(categoryId, roundId);

    let exerciseTypeConfig = {};
    let formatExerciseTypeConfig = {};

    if (exerciseTypeId) {
      const exerciseType = find(store.state.eventDiscipline.exerciseTypes, item => {
        return item.id === exerciseTypeId;
      });
      if (exerciseType) {
        exerciseTypeConfig = exerciseType.config;

        const formatExerciseType = find(format.exercises, item => {
          return item.exerciseTypeId === exerciseTypeId;
        });

        if (formatExerciseType) {
          formatExerciseTypeConfig = formatExerciseType.config;
        }
      }
    }

    const configuration = {};
    merge(configuration,
      discipline.config,
      exerciseTypeConfig,
      format.config,
      formatExerciseTypeConfig);

    return configuration
  },

  getCategoryExerciseTypes: function(categoryId, roundId) {
    const category = find(store.state.categories.items, item => item.id === categoryId)
    const categoryRound = find(category.rounds, item => item.roundId === roundId)
    const format = this.getFormat(categoryId, roundId)

    let exerciseTypes = this.getExerciseTypes(format)

    if (categoryRound.config?.exerciseTypes && categoryRound.config?.exerciseTypes.length) {
      exerciseTypes = exerciseTypes.filter(i => categoryRound.config.exerciseTypes.includes(i))
    }
    return exerciseTypes
  },

  getFormatExerciseTypes: function(categoryId, roundId) {
    const format = this.getFormat(categoryId, roundId);
    return this.getExerciseTypes(format);
  },

  getExerciseTypes: function(format) {
    return map(format.exercises, item => item.exerciseTypeId)
  },

  getMainRankings: function(category, session) {
    let rankings = [];
    forEach(session.rankingTypes, type => {
      let exerciseTypes = ['']
      let includeType = 'regular'
      if (type.open) {
        includeType = 'open';
      } else if (! type.regular) {
        return;
      }

      if (type.rankingType === 'EVENTS') {
        exerciseTypes = this.getFormatExerciseTypes(category.id, session.roundId).filter(fe => {
          if (session.exerciseTypes?.length) {
            return session.exerciseTypes.includes(fe)
          }
          return true
        })
      }

      exerciseTypes.forEach(exerciseType => {
        const ranking = rankingLib.getRanking(category.id, session.roundId, type.rankingType, includeType, null, exerciseType)
        if (ranking) {
          rankings.push(ranking);
        }
      })
    });

    return rankings;
  }
};

