'use strict';

import Vue from 'vue';

import store from 'client/store';

import member from './member.js';

import filter from "lodash/filter";
import find from "lodash/find";
import map from "lodash/map";
import toUpper from "lodash/toUpper";
import trim from "lodash/trim";

export default {

  getParticipation: function(participationId) {
    return find(store.state.participations.items, item => { return item.id === participationId });
  },

  getParticipant: function(participation) {
    const type = participation.participantType;

    switch (type) {
      case 'clubMember':
        return find(store.state.members.items, (item) => { return item.id === participation.participantId });
      case 'group':
        return find(store.state.groups.items, (item) => { return item.id === participation.participantId });
      case 'team':
        return find(store.state.teams.items, (item) => { return item.id === participation.participantId });
    }
  },

  getStatus: function (participation, roundId) {
    const partRound = participation.rounds.find(r => r.roundId === roundId)

    if (! partRound) return null

    return partRound.status
  },

  getClub: function (participant) {
    return find(store.state.clubs.items, club => {
      return club.id === participant.clubId;
    });
  },

  getCategory: function(participation) {
    return find(store.state.categories.items, category => {
      return category.id === participation.categoryId;
    });
  },

  getMembers: function(participant, type) {
    switch (type) {
      case 'clubMember':
        return [participant]
      case 'group':
      case 'team': {
        const members = map(participant.members, m => find(store.state.members.items, i => i.id === m.memberId))
        return members.filter(i => {
          if ( i ) return true

          console.log('missing member', participant.name, type)
          return false
        })
      }
    }
  },

  getGroupDisplay: function(participation) {
    const eventDiscipline = store.state.eventDisciplines.items.find(i => i.id === participation.eventDisciplineId)

    if (eventDiscipline.groupDisplay) return eventDiscipline.groupDisplay

    const discipline = store.state.disciplines.items.find(i => i.id === eventDiscipline.disciplineId)

    if (discipline.labels.groupDisplay) return discipline.labels.groupDisplay

    return 'members'
  },

  getTeamDisplay: function(participation) {
    const eventDiscipline = store.state.eventDisciplines.items.find(i => i.id === participation.eventDisciplineId)

    if (eventDiscipline.teamDisplay) return eventDiscipline.teamDisplay

    return 'team'
  },

  getAddCountry: function(category) {
    const eventDiscipline = store.state.eventDisciplines.items.find(i => i.id === category.eventDisciplineId)
    return eventDiscipline.clubSymbols === 'flags'
  },

  getParticipantNames: function(participation) {
    const type = participation.participantType;
    const participant = this.getParticipant(participation);
    return this.getPersonNames(participant, type, this.getGroupDisplay(participation))
  },

  getPersonNames: function(participant, type, groupDisplay = 'members') {
    switch (type) {
      case 'clubMember':
        return [member.getName(participant)];
      case 'group': {
        if (groupDisplay === 'group') {
          return [participant.name];
        }
        const members = participant.members;
        return map(members, groupMember => {
          const clubMember = find(store.state.members.items, (item) => {
            return item.id === groupMember.memberId
          });
          return member.getName(clubMember);
        });
      }
      case 'team': {
        const members = participant.members;
        return map(members, groupMember => {
          const clubMember = find(store.state.members.items, (item) => {
            return item.id === groupMember.memberId
          });
          return member.getName(clubMember);
        });
      }
    }
  },

  getName: function(participation) {
    const type = participation.participantType;
    const participant = this.getParticipant(participation);
    if (type === 'clubMember') {
      return member.getName(participant);
    }
    else {
      return toUpper(trim(participant.name));
    }
  },

  getClubName: function(participation, participant, addCountry = false) {
    const club = this.getClub(participant)
    const teamDisplay = this.getTeamDisplay(participation)

    let teamPart = null
    if (participation.participantType === 'team') {
      teamPart = participation
    } else {
      teamPart = this.getTeamParticipation(participation)
    }

    let clubName = club.name
    if (teamPart) {
      const team = this.getParticipant(teamPart)

      switch (teamDisplay) {
        case 'club-team':
          clubName = club.name + ' / ' + team.name
          break
        case 'team':
          clubName = team.name
          break
      }
    }

    if (! teamPart) {
      if (participant.trainingCenter) {
        clubName += ' (' + participant.trainingCenter + ')'
      }
    }

    if (addCountry && club.countryId) {
      clubName += ' (' + club.countryId.substring(8,11).toUpperCase() + ')'
    }

    return clubName
  },

  /** Fetch team participation for the individual participation
   *
   * @param participation
   */
  getTeamParticipation: function(participation) {
    const team = find(store.state.teams.items, team => {
      const member = find(team.members, m => m.memberId === participation.participantId)
      return !! member
    })

    if (! team) return null

    return find(store.state.participations.items, p => p.participantType === 'team' && p.participantId === team.id)
  },

  /** fetch the individual participations for the the team participation
   *
    * @param teamParticipationId
   * @returns {*}
   */
  getTeamParticipations: function(teamParticipationId) {
    const teamParticipation = this.getParticipation(teamParticipationId);
    const team = this.getParticipant(teamParticipation);
    const members = this.getMembers(team, 'team');

    return filter(map(members, member =>
      find(store.state.participations.items, item => item.participantId === member.id)
    ), item => item !== undefined);
  },

  getExerciseOptions: function(participation, roundId, exerciseTypeId) {
    const partRound = find(participation.rounds, item => {
      return item.roundId === roundId;
    });

    if (! partRound) {
      return {}
    }

    const exercise = find(partRound.exerciseSelection, item => {
      return item.exerciseTypeId === exerciseTypeId;
    });

    return exercise ? exercise.options : {};
  },

  getExerciseLabel: function(participation, roundId, exerciseTypeId) {
    let exerciseName = Vue.i18n.t('exercise.type.' + exerciseTypeId);
    const options = this.getExerciseOptions(participation, roundId, exerciseTypeId);

    if (options.exerciseTypeId) {
      exerciseName = Vue.i18n.t('exercise.choice') + ': ' +
        Vue.i18n.t('exercise.type.' + options.exerciseTypeId);
    }
    return exerciseName;
  },
};
