import Vue from 'vue';
import config from 'client/config';

export default function (store) {
  let state = {
    appVersion: null,
    baseUrl: null,
    config: null,
    local: false,
    localUser: null,
    remoteApp: null,
  };

  const actions = {
    "environment.get": function (ctx) {
      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/environment').then(result => {
          ctx.commit('environment.set', result.data)
          resolve(result.data)
        }, reject)
      })
    },
    "environment.updateConfig": function(ctx, data) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/localConfigs/' + data.id, data).then(() => {
          // store.commit('environment.config.set', result.data);
          store.dispatch('environment.get').then(resolve);
        }, err => reject(err));
      })
    }
  };

  const mutations = {
    "environment.set": function (state, data) {
      state.local = data.isLocal
      state.localUser = data.localUser
      state.appVersion = data.appVersion
      state.baseUrl = data.baseUrl
      state.config = data.config
      state.remoteApp = data.remote + config.root
    },
  };

  store.registerModule('environment', {
    state: state,
    mutations: mutations,
    actions: actions
  });
}
