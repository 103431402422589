import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';

const filter = require("lodash/filter");

export default function(store) {
  const state = {
    items: [],
    eventId: undefined
  };

  const actions = {
    "clubs.get": function(ctx, opts) {
      if (! opts.eventId) {
          return null;
      }

      if (ctx.state.eventId) {
        socket.emit('leave', 'club:' + ctx.state.eventId);
      }

      store.commit('clubs.setEventId', opts.eventId);

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/events/' + opts.eventId + '/clubs').then(result => {
          store.commit('clubs.setItems', result.data);
          socket.emit('join', 'club:' + opts.eventId);
          resolve(result);
        }, err => reject(err));
      })

    },
    "club.save": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/clubs/' + opts.clubId, opts.data).then(result => {
          store.commit('club.update', result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    },
    "club.add": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/events/' + ctx.state.eventId + '/clubs', opts.data).then(result => {
          store.commit('club.update', result.data);
          resolve(result);
        }, err => reject(err));
      });
    },
    "club.remove": function(state, opts) {
      store.commit('club.remove', opts.clubId);
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/clubs/' + opts.clubId).then(() => {
          resolve();
        }, err => reject(err));
      });
    }
  };

  const mutations = {
    "clubs.setEventId": function(state, eventId) {
      Vue.set(state, 'eventId', eventId);
    },
    "clubs.setItems": function(state, items) {
      Vue.set(state, 'items', items)
    },
    "club.remove": function(state, id) {
      state.items = filter(state.items, function(item) {
        return item.id !== id;
      })
    },
    "club.add": function(state, club) {
      state.items.push(club);
    },
    "club.update": function(state, club) {
      const index = state.items.findIndex(i => i.id === club.id);
      let clubs = state.items;

      if (index >= 0) {
        clubs.splice(index, 1, club);
      }
      else {
        clubs.push(club);
      }

      Vue.set(state, 'items', clubs);
    }
  };

  function listener() {
    /*socket.on('connect', function () {
      if (state.eventId) {
        store.dispatch('clubs.get', {eventId: state.eventId});
      }
    });*/
    socket.on('club.update', function(result) {
      if (result.type === 'update') {
        store.commit('club.update', result.data);
        return;
      }
      if (result.type === 'create') {
        store.commit('club.add', result.data);
      }
    });
    socket.on('club.delete', result => {
      store.commit('club.remove', result.data);
    });
    socket.emit('join', 'club.delete');
  }

  store.registerModule('clubs', {
    state: state,
    mutations: mutations,
    actions: actions
  });

  listener();
}
