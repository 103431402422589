import io from 'socket.io-client';

const socket = location.protocol + "//" + location.hostname + (location.port ? ':' + location.port : '')
console.log('socket url', socket)

const config = {
    'reconnect': true
};
if (!process.env.app_engine) {
    config.upgrade = false;
    config.transports = ['websocket'];
}

const temp = io(socket, config);
export default temp;

window.addEventListener('beforeunload', () => {
    window.state = 'unloading';
});
