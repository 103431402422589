<template>
  <div></div>
</template>

<script>
export default {
  name: "customFieldBase",
  props: ['name', 'config', 'value'],
  data: function() {
    return {
      inputName: '',
      numeric: false
    }
  },
  created: function() {
    this.inputName = this.name + '-Field';
  },
  methods: {
    updateInput: function(input) {
      let value = input
      if (value === Object(value)) {
        value = input.srcElement.value
      }
      if (this.numeric) {
        value = parseFloat(value)
      }
      this.$emit('input', value)
    },
  },
}
</script>

<style scoped>

</style>
